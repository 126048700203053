import React from "react";
import Page from "@components/Layout/page";
import { useRouter } from "next/router";
import { useCompany } from "@hooks/useCompany";
import dynamic from "next/dynamic";
import Bugsnag from "@bugsnag/js";
import { fetcherNew1819 } from "@utils/FetcherNew1819";
import { ContentContainer } from "@components/ContentContainer/ContentContainer";
const CompanyDetailsPage = dynamic(() =>
    import("@components/Pages/CompanyDetailsPage/CompanyDetailsPage")
);

// Company is fetched on the server
export const getServerSideProps = async (ctx) => {
    fetch(
        `https://old.1819.is/api/company_click/${ctx.query.infoID}/${ctx.query.q}`
    ).catch(Bugsnag.notify);

    var uri = "1819/details?slug=" + ctx.query.companySubID;
    // "/" +
    // ctx.query.q;

    try {
        const company = await fetcherNew1819(uri);

        if (!company) {
            return { props: {} };
        }
        try {
            var regex = /(<([^>]+)>)/gi;
            company.description = company.information.replace(regex, "");
        } catch (e) {
            console.log(e);
        }
        return { props: company };
    } catch (error) {
        console.log(error);
        return { props: {} };
    }
};

export default function Item(item) {
    const router = useRouter();
    const company = useCompany(item.laufid_company_id);

    React.useEffect(() => {
        if (!item || Object.entries(item).length === 0 || item.deleted) {
            router.push("/404");
        }
    }, []);

    if (!item || Object.entries(item).length === 0 || item.deleted) {
        return <Page />;
    }

    return (
        <Page
            fluid
            headTitle={item.name}
            headImageUrl={item.cover_photo}
            headDescription={item.description}
            headUrl={`https://laufid.is/info/${item.id}`}
            showsSearchBar
            showsFooter={false}
        >
            <ContentContainer>
                <CompanyDetailsPage
                    item={item}
                    laufid_company={company?.data?.results}
                />
            </ContentContainer>
        </Page>
    );
}
